/* Agent Details Component Specific Styling */
.agent-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.agent-details-container h2 {
  color: #2c3e50;
  border-bottom: 3px solid #3498db;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.agent-details-container h3 {
  color:  #3498db;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.agent-info {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.agent-info p {
  margin-bottom: 0.75rem;
  color: #2c3e50;
}

.agent-info strong {
  color: #3498db;
  min-width: 100px;
  display: inline-block;
}

.tours-table-container {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.table {
  margin-bottom: 0;
}

.table thead {
  background-color: #3498db;
  color: white;
}

.table-bordered th, 
.table-bordered td {
  border: 1px solid #d6dfe8;
}

.table-hover tbody tr:hover {
  background-color: rgba(52, 152, 219, 0.05);
  transition: background-color 0.3s ease;
}

.table-image {
  border-radius: 6px;
  transition: transform 0.3s ease;
}

.table-image:hover {
  transform: scale(1.1);
}

.people-details-container summary {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  background-color: #3498db;
  color: white;
  border-radius: 4px;
  outline: none;
}

.people-details-container summary:hover {
  background-color: #2980b9;
}

.badge {
  padding: 0.4rem 0.6rem;
  border-radius: 4px;
  font-weight: 500;
  text-transform: capitalize;
}

.badge-success1 {
  background-color: #2ecc71;
  color: white;
}

.badge-warning {
  background-color: #f39c12;
  color: white;
}

.badge-secondary {
  background-color: #2ecc71;
  color: white;
}

@media (max-width: 768px) {
  .agent-details-container {
      padding: 1rem;
  }

  .tours-table-container {
      overflow-x: auto;
  }

  .table-responsive {
      max-height: 300px;
      overflow-y: auto;
  }
}

.btn-sm {
  padding: 2px 8px; /* Adjust padding */
  font-size: 12px; /* Smaller font size */
  line-height: 1.6;
}
