.imgg{
    background-image: url('../../Img/Travel-Guide-Vietnam.jpg');
    background-size: cover;
} 

/* Ensure the container has a background image, if needed */

  
  /* Make sure the text and background contrast well */
  .text-white {
    color: #ffffff;
  }
  
  /* Ensure the input and select fields take full width in smaller screens */
  .form-control, .form-select {
    width: 100%;
  }
  
  /* Adjust spacing for smaller screens */
  @media (max-width: 768px) {
    .container {
      padding: 0 15px;
    }
    
    .card-body {
      padding: 3rem;
    }
  }
  
  /* Adjust padding and margins for responsiveness */
  @media (max-width: 576px) {
    .container-fluid {
      padding: 0;
    }
    
    .mb-5 {
      margin-bottom: 3rem !important;
    }
    
    .py-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  
    .text-white {
      font-size: 0.9rem;
    }
  }
  