.dash{
  background: rgb(208, 201, 201);
}

.dash {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.section {
  width: 100%;
  
}

hr {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #ddd;
}

@media (min-width: 300px) and (max-width: 540px) {
  .dash {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
}

.dash{
  /* Re-apply Bootstrap styles only inside this ID */
 @import url('/css/bootstrap.min.css');
}
