.carousel-image-container {
    width: 100%;
    height: 90px; /* Base height for mobile */
    overflow: hidden;
  }

  .carousel-indicators {
    display: none;
  }
  .carousel-control-next .visually-hidden,
.carousel-control-prev .visually-hidden {
  display: none;
}
  
  .carousel-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-caption h5 {
    font-size: 1rem;
  }
  
  .carousel-caption h1 {
    font-size: 1.5rem;
  }
  
  .carousel-caption .btn {
    font-size: 0.8rem;
  }
  
@media (max-width:480px){
    .carousel-image-container{
        height: 80vh;
    }
}

  /* Media queries for responsive design */
  @media (min-width: 576px) {
    .carousel-image-container {
      height: 80vh;
      background: #000;
    }
    
    .carousel-caption h5 {
      font-size: 1.1rem;
    }
  
    .carousel-caption h1 {
      font-size: 2rem;
    }
  
    .carousel-caption .btn {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 768px) {
    .carousel-image-container {
      height: 500px;
    }
  
    .carousel-caption h5 {
      font-size: 1.2rem;
    }
  
    .carousel-caption h1 {
      font-size: 2.5rem;
    }
  
    .carousel-caption .btn {
      font-size: 1rem;
      padding: 0.5rem 1rem !important;
    }
  }
  
  @media (min-width: 992px) {
    .carousel-image-container {
      height: 600px;
    }
  
    .carousel-caption h5 {
      font-size: 1.3rem;
    }
  
    .carousel-caption h1 {
      font-size: 3rem;
    }
  }
  
  @media (min-width: 1200px) {
    .carousel-image-container {
      height: 700px;
    }
  
    .carousel-caption h5 {
      font-size: 1.4rem;
    }
  
    .carousel-caption h1 {
      font-size: 3.5rem;
    }
  }