h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
   
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    margin-top: 20px;
    margin-bottom: 30px;
    letter-spacing: 1px; /* Slight spacing for modern look */
    transition: all 0.3s ease;
  }
  
  h1:hover {
    /* color: #6dd5fa; Slight color change on hover */
    /* text-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); Enhanced shadow on hover */
  }
  