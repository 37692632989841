.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; /* Optional: background for the loader */
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #69047f; /* Primary color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  