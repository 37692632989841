/* Reduce the container width */
.navbar-container {
  max-width: 700px; /* Adjust as needed */
  margin: 0 auto;
}

/* Adjust navbar brand logo */
.imglogo {
  width: 120px;
  height: auto;
}

.spacer1 {
  display: inline-block;
  width: 500px; /* Adjust this width as needed */
}

/* Adjust navbar link spacing */
.nav-item {
  margin: 0 1px; /* Reduce horizontal spacing */
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-collapse {
  justify-content: center; /* Center items in the collapse */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar-container {
    max-width: 100%;
  }
  .navbar-nav {
    flex-direction: column;
  }
}


/* Adjust navbar height */
.navbar {
  padding: 0rem 1rem; /* Reduce the padding to decrease height */
}

/* Adjust brand logo size */
.imglogo {
  max-height: 50px; /* Reduce the height of the logo */
  width: auto;      /* Maintain aspect ratio */
}

/* Adjust nav links padding */
.nav-link {
  padding: 0.25rem 0.5rem; /* Reduce padding around links */
}

/* Adjust navbar toggler */
.navbar-toggler {
  padding: 0.25rem 0.5rem; /* Reduce padding on the toggler button */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column; /* Stack items vertically on small screens */
  }
}
