/* Profile.css */

/* Root container for the profile */
.profile-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
    position: relative;
    overflow: hidden;
  }
  
  /* Profile header */
  .profile-header {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .profile-header h2 {
    font-size: 24px;
    color: #4caf50;
    margin-bottom: 5px;
  }
  
  .profile-header p {
    font-size: 14px;
    color: #666;
  }
  
  /* Profile information display */
  .profile-info {
    margin-top: 20px;
  }
  
  .profile-info p {
    font-size: 16px;
    margin: 10px 0;
    color: #555;
  }
  
  .profile-info p strong {
    font-weight: bold;
    color: #222;
  }
  
  /* Edit form styling */
  .profile-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #444;
  }
  
  .profile-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .profile-form input:focus {
    border-color: #4caf50;
    outline: none;
  }
  
  /* Buttons styling */
  .profile-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .profile-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .profile-buttons button.save-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .profile-buttons button.cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  .profile-buttons button.edit-btn {
    background-color: #008cba;
    color: white;
    width: 100%;
  }
  
  .profile-buttons button:hover {
    transform: scale(1.05);
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .profile-container {
      padding: 15px;
    }
  
    .profile-buttons {
      flex-direction: column;
      gap: 10px;
    }
  
    .profile-buttons button {
      width: 100%;
    }
  }
  