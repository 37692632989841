.footerContainer{
    position: fixed;
    background: white;
    z-index: 1 !important;
    bottom: 0;  
    text-align: center;
    border-top: 1px solid darkslategray ;
    width: 100vw;
    height: 5vh;
    background: whitesmoke;
}

.footerContainer span{
    color: #741051;
    
}