.login-page {
    background-image: url("../../Img/forr.jpg");
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  
  .background-wrapper {
    width: 100%;
    padding: 3rem 1rem;
  }
  
  .content-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .company-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .company-name {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .highlight {
    color: #007bff;
  }
  
  .company-tagline {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 1rem;
  }
  
  .login-section {
    width: 100%;
    max-width: 400px;
  }
  
  .login-box {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  
  .login-title {
    text-align: center;
    color: #505050;
    margin-bottom: 1.5rem;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #17a2b8;
    border: none;
    cursor: pointer;
    margin-top: 1.5rem;
  }
  
  .form-divider {
    margin-top: 1rem;
    border-top: 1px solid #dee2e6;
  }

  .password-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  

  .password-container input {
    flex: 1;
    padding-right: 10px; /* Space for the icon */
  }
  
  .toggle-password {
    position: absolute;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 18px; /* Adjust icon size */
    color: #2f7fcf; /* Optional: Icon color */
  }
  