/* Container settings */
.navbar-container {
  max-width: 700px; /* Adjust as needed */
  margin: 0 auto;
  padding-bottom: 500px;
}

/* Adjust navbar brand logo */
.imglogo {
  width: 150px;
  height: 80px;
  object-fit: contain;
}

/* Navbar item styling */
.nav-item {
  margin: 0 1px;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-collapse {
  justify-content: center; /* Center items in the collapse */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar-container {
    max-width: 100%;
  }
  .navbar-nav {
    flex-direction: column;
  }
}

/* Adjust navbar height */
.navbar {
  padding: 0rem 1rem; /* Reduce the padding to decrease height */
}

/* Adjust nav links padding */
.nav-link {
  padding: 0.25rem 0.5rem; /* Reduce padding around links */
}

/* Adjust navbar toggler */
.navbar-toggler {
  padding: 0.25rem 0.5rem; /* Reduce padding on the toggler button */
}

/* Spacer for larger screens */
.spacer {
  display: inline-block;
  width: 800px; /* Adjust this width as needed */
}

/* Adjust navbar background */
.navbar {
  background-color: #f8f9fa;
}

.navbar-light .navbar-toggler-icon {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Profile dropdown menu */
.nav-item.dropdown .dropdown-menu {
  min-width: 200px;
}

.nav-link.dropdown-toggle {
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column; /* Stack items vertically on small screens */
  }
}
